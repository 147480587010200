import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useCart } from '@bluheadless/ui-logic/src/hooks/cart/useCart'
import Bag2Icon from '../../atoms/icons/bag-2-icon'
import Bag2FilledHeaderIcon from '@/components/ui/atoms/icons/bag-2-filled-header-icon'
import Progress from '../../atoms/progress'
import Button from '../button'
import { ButtonStyled } from './minicart.styled'
import { cx } from '@emotion/css'

const MiniCartTrigger = ({ className, ...props }) => {
	const { loading, itemsQty: cartItemsQty } = useCart()
	const {
		catalog: { disableShop },
	} = useConfig()

	return disableShop ? null : (
		<ButtonStyled
			badgeProps={{
				badgeContent: loading ? <Progress /> : cartItemsQty || 0,
			}}
			Icon={cartItemsQty > 0 ? Bag2FilledHeaderIcon : Bag2Icon}
			className={cx('minicart-trigger', className)}
			{...props}
		/>
	)
}

MiniCartTrigger.defaultProps = {
	variant: 'icon',
}

MiniCartTrigger.propTypes = {
	...Button.propTypes,
}

export default MiniCartTrigger
