/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Bag2IconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M2.2 23.1V5.8h4.9c0-1.3.5-2.6 1.4-3.5.9-.9 2.2-1.5 3.5-1.4 1.3 0 2.6.5 3.5 1.4 1 .9 1.5 2.2 1.5 3.5h4.9v17.3H2.2zM8.3 5.8h7.3c0-1-.4-1.9-1.1-2.6-.7-.7-1.6-1.1-2.6-1-1.9-.1-3.5 1.5-3.6 3.6zM3.5 21.9h17V7.1h-17v14.8zm8.5-8.6c2.8 0 5-2.2 5-5h-1.3c0 1-.4 2-1.1 2.6-.7.8-1.6 1.2-2.6 1.1-2.1 0-3.7-1.7-3.7-3.7H7c0 1.3.5 2.6 1.5 3.5.9 1 2.2 1.5 3.5 1.5zM3.5 7.1z" />
	</svg>
))
Bag2IconSvg.displayName = 'Bag2IconSvg'

const Bag2Icon = forwardRef((props, ref) => <Icon component={Bag2IconSvg} ref={ref} {...props} />)
Bag2Icon.displayName = 'Bag2Icon'

Bag2Icon.defaultProps = {
	...Icon.defaultProps,
}
Bag2Icon.propTypes = {
	...Icon.propTypes,
}

export default Bag2Icon
export { Bag2IconSvg }
