import { styled } from '@mui/system'
import MenuList from '@mui/material/MenuList'
import Button from '../../molecules/button'
import StorelocatorLink from '@bluheadless/ui/src/organisms/footer/storelocator-link'

const Root = styled(MenuList)`
	margin-top: 0;
	margin-bottom: 36px;
	padding: 0;
	padding-top: 72px;
	.MuiMenuItem-root {
		border: 0;
		padding: ${({ theme }) => theme.spacing(0, 2, 3)};
		min-height: unset;
		&:last-of-type {
			padding-bottom: 0;
		}
		&:hover {
			background: transparent;
		}
		.MuiLink-root {
			display: flex;
			align-items: center;
			&:hover {
				color: ${({ theme }) => theme.palette.grey.main};
			}
			> button,
			> svg {
				margin-right: 8px;
				font-size: ${({ theme }) => theme.typography.pxToRem(16)};
			}
		}
		.MuiTypography-root {
			font-size: ${({ theme }) => theme.typography.pxToRem(12)};
		}
	}
`

const ButtonIcon = styled(Button)`
	padding: 0;
	.MuiSvgIcon-root {
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
`

const StorelocatorLinkStyled = styled(StorelocatorLink)`
	text-transform: capitalize;
	min-height: 20px;
	margin: 0;
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	letter-spacing: unset;
	line-height: 1;
	.MuiButton-startIcon {
		margin-right: 8px;
	}
`

export { ButtonIcon, Root, StorelocatorLinkStyled }
