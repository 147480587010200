/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const HeartIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M16.6 4c1.2 0 2.3.5 3.1 1.3 1.7 1.7 1.7 4.5 0 6.2L12 19.2l-7.7-7.7C3.5 10.8 3 9.6 3 8.4s.5-2.3 1.3-3.1S6.2 4 7.4 4s2.3.5 3.1 1.3l.6.6.9 1 .9-.9.6-.6c.9-.9 2-1.4 3.1-1.4m0-1.2c-1.4 0-2.9.5-4 1.6l-.6.7-.6-.6c-1.1-1.1-2.5-1.7-4-1.7s-2.9.6-4 1.7c-2.2 2.2-2.2 5.8 0 8l8.6 8.6 8.6-8.6c2.2-2.2 2.2-5.8 0-8-1.1-1.2-2.5-1.7-4-1.7z" />
	</svg>
))
HeartIconSvg.displayName = 'HeartIconSvg'

const HeartIcon = forwardRef((props, ref) => <Icon component={HeartIconSvg} ref={ref} {...props} />)
HeartIcon.displayName = 'HeartIcon'

HeartIcon.defaultProps = {
	...Icon.defaultProps,
}
HeartIcon.propTypes = {
	...Icon.propTypes,
}

export default HeartIcon
export { HeartIconSvg }
