/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '@bluheadless/ui/src/atoms/icon/icon'

const Bag2FilledHeaderIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M2.2 23.1V5.8h4.9c0-1.3.5-2.6 1.4-3.5S10.7.8 12 .9c1.3 0 2.6.5 3.5 1.4 1 .9 1.5 2.2 1.5 3.5h.7v8.8h4.2v8.5H2.2zM8.3 5.8h7.3c0-1-.4-1.9-1.1-2.6-.7-.7-1.6-1.1-2.6-1-1.9-.1-3.5 1.5-3.6 3.6zm3.7 7.5c2.8 0 5-2.2 5-5h-1.3c0 1-.4 2-1.1 2.6-.7.8-1.6 1.2-2.6 1.1-2.1 0-3.7-1.7-3.7-3.7H7c0 1.3.5 2.6 1.5 3.5.9 1 2.2 1.5 3.5 1.5z"
			fill="currentColor"
		/>
	</svg>
))
Bag2FilledHeaderIconSvg.displayName = 'Bag2FilledHeaderIconSvg'

const Bag2FilledHeaderIcon = forwardRef((props, ref) => (
	<Icon component={Bag2FilledHeaderIconSvg} ref={ref} {...props} />
))
Bag2FilledHeaderIcon.displayName = 'Bag2FilledHeaderIcon'

Bag2FilledHeaderIcon.defaultProps = {
	...Icon.defaultProps,
}
Bag2FilledHeaderIcon.propTypes = {
	...Icon.propTypes,
}

export default Bag2FilledHeaderIcon
export { Bag2FilledHeaderIconSvg }
