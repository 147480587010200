import { useRouter } from 'next/router'
import { PAGE_PATH_HOME } from '@bluheadless/ui-logic/src/constants'
import { cx } from '@emotion/css'
import { H1, LogoRoot } from './logo.styled'
import Link from '../../molecules/link'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'

const Logo = ({ className }) => {
	const router = useRouter()
	const { siteName: siteName } = useConfig()
	return (
		<LogoRoot className={cx('Logo-root', className)}>
			{router?.asPath.split('?')[0] === PAGE_PATH_HOME ? (
				<H1>
					<svg xmlns="http://www.w3.org/2000/svg" width="149" height="24" viewBox="0 0 149 24" fill="currentColor">
						<title>{siteName}</title>
						<g clipPath="url(#clip0_850_175)">
							<path d="M53.4565 0.84082H75.3523V6.32059H53.4565V0.84082Z" fill="inherit" />
							<path d="M75.3608 14.7487H53.4651V9.22559H75.3608V14.7487Z" fill="inherit" />
							<path
								d="M53.4055 20.74C53.4055 18.9972 54.7436 17.6272 56.4483 17.6012C58.1614 17.5839 59.5506 19.0059 59.5421 20.7746C59.5421 22.5608 58.1784 23.9047 56.4056 23.8874C54.701 23.87 53.4055 22.5001 53.4055 20.7313V20.74Z"
								fill="inherit"
							/>
							<path
								d="M37.9531 24C44.4678 24 49.749 18.6274 49.749 12C49.749 5.37258 44.4678 0 37.9531 0C31.4384 0 26.1572 5.37258 26.1572 12C26.1572 18.6274 31.4384 24 37.9531 24Z"
								fill="inherit"
							/>
							<path
								d="M11.7959 24C18.3106 24 23.5918 18.6274 23.5918 12C23.5918 5.37258 18.3106 0 11.7959 0C5.28121 0 0 5.37258 0 12C0 18.6274 5.28121 24 11.7959 24Z"
								fill="inherit"
							/>
							<path
								d="M98.7993 11.2197C97.5549 15.503 96.3531 19.6735 95.1343 23.8874H93.0377C92.1428 21.1995 91.2393 18.4856 90.2677 15.5723C89.3131 18.4509 88.4182 21.1648 87.5233 23.896H85.2817C84.1055 19.6822 82.9379 15.503 81.7446 11.2371H84.1907C85.0004 14.1244 85.8016 17.0116 86.6113 19.8989C86.6539 19.8989 86.6965 19.8989 86.7477 19.8989C87.6426 17.029 88.5375 14.159 89.458 11.2371H91.0774C92.0064 14.0203 92.9439 16.8209 93.89 19.6215L94.0434 19.6388C94.819 16.8469 95.5861 14.055 96.3702 11.2284H98.7993V11.2197Z"
								fill="inherit"
							/>
							<path
								d="M139.395 11.2112C141.005 11.2112 142.557 11.1938 144.108 11.2112C144.568 11.2112 145.02 11.3152 145.48 11.3759C147.466 11.6534 148.403 13.1707 148.301 15.2083C148.233 16.5089 147.679 17.5493 146.622 18.3037C146.503 18.3904 146.409 18.4858 146.213 18.6505C147.133 20.3759 148.062 22.0927 149.009 23.8615H146.528C145.693 22.3615 144.841 20.8181 143.971 19.2488H141.696V23.8788H139.403V11.1938L139.395 11.2112ZM141.704 16.8904C141.704 16.8904 143.605 16.9424 144.542 16.8904C145.31 16.847 145.77 16.1274 145.812 15.2517C145.864 14.2112 145.497 13.5002 144.662 13.3701C143.707 13.2141 141.713 13.2834 141.713 13.2834V16.8904H141.704Z"
								fill="inherit"
							/>
							<path
								d="M114.166 11.2372V13.5349H108.541V16.1794H112.913V18.4511H108.541V21.5985H114.311V23.8788H106.18V11.2285H114.158L114.166 11.2372Z"
								fill="inherit"
							/>
							<path
								d="M125.34 11.2285H127.564C129.047 15.4424 130.53 19.6476 132.03 23.9135H129.61C129.243 22.9164 128.86 21.8933 128.476 20.8615H124.419C124.061 21.8326 123.695 22.8297 123.303 23.8875H120.882C122.374 19.6476 123.848 15.4597 125.331 11.2285H125.34ZM127.718 18.6071C127.292 17.4193 126.908 16.3441 126.516 15.269C126.465 15.269 126.414 15.269 126.363 15.2603C125.979 16.3528 125.596 17.4453 125.195 18.6071H127.718Z"
								fill="inherit"
							/>
						</g>
						<defs>
							<clipPath id="clip0_850_175">
								<rect width="149" height="24" fill="inherit" />
							</clipPath>
						</defs>
					</svg>
				</H1>
			) : (
				<Link href={PAGE_PATH_HOME}>
					<svg xmlns="http://www.w3.org/2000/svg" width="149" height="24" viewBox="0 0 149 24" fill="currentColor">
						<title>{siteName}</title>
						<g clipPath="url(#clip0_850_175)">
							<path d="M53.4565 0.84082H75.3523V6.32059H53.4565V0.84082Z" fill="inherit" />
							<path d="M75.3608 14.7487H53.4651V9.22559H75.3608V14.7487Z" fill="inherit" />
							<path
								d="M53.4055 20.74C53.4055 18.9972 54.7436 17.6272 56.4483 17.6012C58.1614 17.5839 59.5506 19.0059 59.5421 20.7746C59.5421 22.5608 58.1784 23.9047 56.4056 23.8874C54.701 23.87 53.4055 22.5001 53.4055 20.7313V20.74Z"
								fill="inherit"
							/>
							<path
								d="M37.9531 24C44.4678 24 49.749 18.6274 49.749 12C49.749 5.37258 44.4678 0 37.9531 0C31.4384 0 26.1572 5.37258 26.1572 12C26.1572 18.6274 31.4384 24 37.9531 24Z"
								fill="inherit"
							/>
							<path
								d="M11.7959 24C18.3106 24 23.5918 18.6274 23.5918 12C23.5918 5.37258 18.3106 0 11.7959 0C5.28121 0 0 5.37258 0 12C0 18.6274 5.28121 24 11.7959 24Z"
								fill="inherit"
							/>
							<path
								d="M98.7993 11.2197C97.5549 15.503 96.3531 19.6735 95.1343 23.8874H93.0377C92.1428 21.1995 91.2393 18.4856 90.2677 15.5723C89.3131 18.4509 88.4182 21.1648 87.5233 23.896H85.2817C84.1055 19.6822 82.9379 15.503 81.7446 11.2371H84.1907C85.0004 14.1244 85.8016 17.0116 86.6113 19.8989C86.6539 19.8989 86.6965 19.8989 86.7477 19.8989C87.6426 17.029 88.5375 14.159 89.458 11.2371H91.0774C92.0064 14.0203 92.9439 16.8209 93.89 19.6215L94.0434 19.6388C94.819 16.8469 95.5861 14.055 96.3702 11.2284H98.7993V11.2197Z"
								fill="inherit"
							/>
							<path
								d="M139.395 11.2112C141.005 11.2112 142.557 11.1938 144.108 11.2112C144.568 11.2112 145.02 11.3152 145.48 11.3759C147.466 11.6534 148.403 13.1707 148.301 15.2083C148.233 16.5089 147.679 17.5493 146.622 18.3037C146.503 18.3904 146.409 18.4858 146.213 18.6505C147.133 20.3759 148.062 22.0927 149.009 23.8615H146.528C145.693 22.3615 144.841 20.8181 143.971 19.2488H141.696V23.8788H139.403V11.1938L139.395 11.2112ZM141.704 16.8904C141.704 16.8904 143.605 16.9424 144.542 16.8904C145.31 16.847 145.77 16.1274 145.812 15.2517C145.864 14.2112 145.497 13.5002 144.662 13.3701C143.707 13.2141 141.713 13.2834 141.713 13.2834V16.8904H141.704Z"
								fill="inherit"
							/>
							<path
								d="M114.166 11.2372V13.5349H108.541V16.1794H112.913V18.4511H108.541V21.5985H114.311V23.8788H106.18V11.2285H114.158L114.166 11.2372Z"
								fill="inherit"
							/>
							<path
								d="M125.34 11.2285H127.564C129.047 15.4424 130.53 19.6476 132.03 23.9135H129.61C129.243 22.9164 128.86 21.8933 128.476 20.8615H124.419C124.061 21.8326 123.695 22.8297 123.303 23.8875H120.882C122.374 19.6476 123.848 15.4597 125.331 11.2285H125.34ZM127.718 18.6071C127.292 17.4193 126.908 16.3441 126.516 15.269C126.465 15.269 126.414 15.269 126.363 15.2603C125.979 16.3528 125.596 17.4453 125.195 18.6071H127.718Z"
								fill="inherit"
							/>
						</g>
						<defs>
							<clipPath id="clip0_850_175">
								<rect width="149" height="24" fill="inherit" />
							</clipPath>
						</defs>
					</svg>
				</Link>
			)}
		</LogoRoot>
	)
}

export default Logo
